.error-custom-dialog-z {
	z-index: 200000 !important;
}
.error-dialog-content {
	background-color: var(--webl_green) !important;
	align-items: center !important;
	gap: 10px;
}

.error-dialog-content .desc {
	color: white !important;
	font-weight: bold;
}
.error-dialog-content .header {
	color: white !important;
	font-weight: bold;
}

.error-dialog-content .confirmation-buttons {
	justify-content: center !important;
}

.error-dialog-content .confirmation-buttons .confirm_btn,
.error-dialog-content .confirmation-buttons .cancel_btn {
	color: var(--webl_green) !important;
	background-color: var(--webl_yellow) !important;
	font-weight: bold !important;
	width: max-content !important;
}
