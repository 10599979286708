#select-suggestions-switch-container {
	margin-top: 20px;
}

#select-suggestions-switch-container,
#save-option-switch-container {
	display: flex;
}

#select-suggestions-switch-container :first-child,
#save-option-switch-container :first-child {
	margin-right: 10px;
}

#save-option-switch-container {
	margin-top: 10px;
}
