.loading-bar {
	width: calc(100vw - 2 * var(--page_padding) - 2px);
	height: 3px;
	background-color: var(--webl_lightgray);
	position: absolute;
	z-index: 1050;
	top: calc(3 * var(--page_padding) + 1px);
}

.loading-bar::after {
	z-index: 1050;
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	width: 0;
	height: 100%;
	background-color: var(--webl_lightgray);
	animation: loading-animation 2s linear infinite;
}

@keyframes loading-animation {
	0% {
		width: 0;
		background-color: var(--webl_black);
	}
	100% {
		width: 100%;
		background-color: var(--webl_black);
	}
}
