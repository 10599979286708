/* NOTE THEME */

/* Font*/
@font-face {
	font-family: "Rock Salt";
	src: url("../../fonts/Rock Salt/RockSalt.ttf") format("truetype");
	font-weight: normal;
}

.webl-html[webl_theme="Note"] {
	background-color: transparent !important;
	display: inline-block;
}

.webl-html table[webl_theme="Note"] {
	border-spacing: 0px 1px !important;
}

/* Text Cells */
.webl-html table td[webl_theme="Note"],
.webl-html table td[webl_theme="Note"] select,
.webl-html table td[webl_theme="Note"] a span,
.webl-html table td[webl_theme="Note"] select option {
	font-family: "Rock Salt" !important;
	background: #ffffff !important;
	box-shadow: 0 1px 0 #cccccc;
	color: #000000 !important;
	padding: 3px 6px !important;
}

/* Input Cells */
.webl-html table td[webl_theme="Note"][contenteditable] {
	background-color: #e6e6e6 !important;
	-webkit-border-radius: 0 !important;
	-moz-border-radius: 0 !important;
	border-radius: 0 !important;
	color: #000000 !important;
}

/* Sum Cells */
.webl-html table td[webl_theme="Note"][x\:fmla] {
	background-color: #000000 !important;
	-webkit-border-radius: 0 !important;
	-moz-border-radius: 0 !important;
	border-radius: 0;
	color: #ffffff !important;
}

/* Empty Cells */
.webl-html table td[webl_theme="Note"]:empty {
	background-color: #ffffff !important;
	box-shadow: none !important;
	border: none !important;
	outline: 0px !important;
}

/* Aditional Properties */
.webl-html table[table_spacing="true"][webl_theme="Note"] {
	border-collapse: unset;
	border-spacing: 5px !important;
	padding: 0px 5px;
}

.webl-html td[gridlines="true"][webl_theme="Note"],
.webl-html td[gridlines="true"][webl_theme="Note"]:empty {
	border: 1px solid #f2f2f2 !important;
	border-collapse: collapse;
	padding: 0px 5px;
}

.webl-html td[contenteditable][input_borders="true"][webl_theme="Note"] {
	outline: #2f2f2f solid 1px !important;
	padding: 0px 5px;
}

.webl-html td[round_borders="true"][webl_theme="Note"] {
	border-radius: 5px !important;
	padding: 0px 5px;
}
