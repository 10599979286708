.rows-header-border {
	width: 1px;
	top: calc(3 * var(--page_padding));
	left: var(--page_padding);
	height: calc(100vh - 3 * var(--page_padding) - 38px);
	background-color: var(--webl_black);
	position: absolute;
	z-index: 1003;
}

.columns-header-border {
	height: 1px;
	top: calc(4 * var(--page_padding));
	left: 0px;
	background-color: var(--webl_black);
	position: absolute;
	z-index: 1003;
}

.corner-icon-container {
	display: flex;
	position: absolute;
	z-index: 1003;
	height: calc(var(--page_padding) - 3px);
	width: calc(var(--page_padding) - 2px);
	background-color: white;
}

.corner-icon-container img {
	width: auto;
	height: 100%;
}
