#webl-header-container {
	width: 100%;
	display: flex;
	flex-direction: column;
	min-height: 100%;
}
/* Top part */
#webl-header {
	width: 100%;
	height: var(--page_padding);
	display: flex;
	margin: 0px auto;
}

#webl-header-left-border,
#webl-header-right-border {
	display: flex;
	height: 100%;
	width: calc(var(--page_padding) + 1px);
}
#webl-header-right-border {
	border-left: 1px solid var(--webl_black);
	margin-left: auto;
}

#webl-header-left-border {
	border-right: 1px solid var(--webl_black);
	margin-right: auto;
}
#webl-header-inner-container {
	display: flex;
	width: calc(100% - var(--page_padding) * 2.5);
	justify-content: space-between;
	align-items: center;
}
#webl-header-left-item,
#webl-header-right-item {
	display: flex;
	cursor: pointer;
	align-items: center;
}
#webl-header-right-item {
	font-size: 11px;
	font-weight: bold;
}
#webl-header-left-item > img {
	width: auto;
	height: 25px;
	padding-left: 0;
}
#webl-header-right-item > img {
	width: var(--page_padding);
	height: 30px;
}

/* Outline part */
#webl-outline-top {
	width: 100%;
	height: var(--page_padding);
	border-top: 1px solid var(--webl_black);
	border-bottom: 1px solid var(--webl_black);
	display: flex;
	align-items: center;
}
#webl-outline-top-left,
#webl-outline-top-right {
	height: 100%;
	width: calc(var(--page_padding) + 1px);
}
#webl-outline-top-left img {
	width: auto;
	height: 100%;
}
#webl-outline-title {
	display: flex;
	font-size: 18px;
	width: calc(100% - var(--page_padding) * 2.5);
	align-items: center;
	height: var(--page_padding);
	align-items: center;
}

#webl-outline-top-right {
	border-left: 1px solid var(--webl_black);
	margin-left: auto;
}

#webl-outline-top-left {
	border-right: 1px solid var(--webl_black);
	margin-right: auto;
}
