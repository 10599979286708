/* FOREST GREEN THEME */

@font-face {
	font-family: "Sofia Sans Cond";
	src: url("../../fonts/Sofia Sans Cond/SofiaSansCondensed-Regular.ttf")
		format("truetype");
	font-weight: normal;
}

@font-face {
	font-family: "Sofia Sans Cond";
	src: url("../../fonts/Sofia Sans Cond/SofiaSansCondensed-Bold.ttf")
		format("truetype");
	font-weight: bold;
}

@font-face {
	font-family: "Sofia Sans Cond";
	src: url("../../fonts/Sofia Sans Cond/SofiaSansCondensed-Italic.ttf")
		format("truetype");
	font-style: italic;
}

.webl-html[webl_theme="Forest Green"] {
	background-color: transparent !important;
	display: inline-block;
}

.webl-html table[webl_theme="Forest Green"] {
	border-spacing: 10px 6px !important;
}

/* Text Cells */
.webl-html td[webl_theme="Forest Green"],
.webl-html td[webl_theme="Forest Green"] > *,
.webl-html td[webl_theme="Forest Green"] > select > option,
.webl-html td[webl_theme="Forest Green"] > a > span {
	font-family: "Sofia Sans Cond" !important;
	color: #1a1a1a !important;
	border: 0px !important;
}
.webl-html td[webl_theme="Forest Green"] {
	border-radius: 0px !important;
	outline: 1px solid #cccccc !important;
	background: #ffffff !important;
	padding: 4px;
}

/* Input Cells */
.webl-html td[contenteditable][webl_theme="Forest Green"] {
	background-color: #e6e6e6 !important;
	outline: 1px solid #395643 !important;
	border: none !important;
	border-radius: 0px !important;
	color: #1a1a1a !important;
}

/* Sum Cells */
.webl-html td[x\:fmla][webl_theme="Forest Green"] {
	background-color: #395643 !important;
	outline: 1px solid #395643 !important;
	border: none !important;
	border-radius: 0px !important;
	color: #cccccc !important;
}

/* Empty Cells */
.webl-html table td[webl_theme="Forest Green"]:empty {
	background-color: #ffffff !important;
	box-shadow: none !important;
	border: none !important;
	outline: 0px !important;
}

/* Aditional Properties */
.webl-html table[table_spacing="true"][webl_theme="Forest Green"] {
	border-collapse: unset;
	border-spacing: 5px !important;
	padding: 0px 5px;
}

.webl-html td[gridlines="true"][webl_theme="Forest Green"],
.webl-html td[gridlines="true"][webl_theme="Forest Green"]:empty {
	border: 1px solid #cccccc !important;
	border-collapse: collapse;
	padding: 0px 5px;
}

.webl-html
	td[contenteditable][input_borders="true"][webl_theme="Forest Green"] {
	outline: #395643 solid 2px !important;
	padding: 0px 5px;
}

.webl-html td[round_borders="true"][webl_theme="Forest Green"] {
	border-radius: 5px !important;
	padding: 0px 5px;
}
