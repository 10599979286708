#webl-control-panel-text-description-container {
	display: flex;
	display: flex;
	width: 100%;
	justify-content: space-between;
	align-items: center;
}

#webl-control-panel-text-description-container > button {
	display: flex;
	width: 180px;
	border: 0px solid transparent;
	border-radius: 8px;
	background-color: var(--webl_black);
	color: white;
	height: 45px;
	padding: 10px 30px;
	font-weight: bold;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	border: 1px solid var(--webl_black) !important;
}

.draft-thumbnail {
	width: 60px !important;
}

#webl-control-panel-text-description-container > button:hover {
	background-color: var(--webl_green);
}

#webl-control-panel-text-description-container :nth-child(1) {
	margin-right: 10px;
}

#webl-control-panel-table-container {
	width: 100%;
	margin-top: 10px;
	overflow-x: auto;
}

#webl-control-panel-table-container table {
	width: 100%;
	text-align: left;
	font-size: 18px;
	border-collapse: collapse;
}

#webl-control-panel-table-container thead th > div {
	border-bottom: 1px solid var(--webl_black);
	cursor: pointer;
}

#webl-control-panel-table-container th:first-child > div,
#webl-control-panel-table-container td:first-child > div:not(:first-child) {
	padding-left: var(--page_padding);
}

#webl-control-panel-table-container th > div,
#webl-control-panel-table-container td > div {
	padding: 8px 15px;
	text-wrap: nowrap;
}

#webl-control-panel-table-container th:not(:last-child) > div,
#webl-control-panel-table-container td > div {
	width: calc(100% - 10px);
}

#webl-control-panel-table-container thead .file-name-column > div,
#webl-control-panel-table-container thead .date-column > div {
	display: flex;
	justify-content: space-between;
	border-right: 1px solid var(--webl_black);
}

.actions-column {
	width: 180px;
}

.actions-column > * {
	cursor: pointer;
}

.date-column,
.status-column {
	width: 15%;
}

/* #webl-control-panel-container {
	min-height: 420px;
} */

#webl-control-panel-table-container td {
	height: 60px;
	vertical-align: middle;
}

#webl-control-panel-table-container tbody tr {
	border-bottom: 1px solid #9c9c9c;
}

.file-name-column {
	font-weight: bold;
}

.file-name-column > div {
	display: flex;
	align-items: center;
}

.file-name-number {
	font-size: 28px;
	font-weight: normal;
	padding-right: 20px;
}

#webl-control-panel-table-container tbody .actions-column div {
	display: flex;
	justify-content: space-between;
	padding: 0px 10px;
}

#webl-control-panel-table-container tbody .actions-column div img {
	width: 30px;
	height: 30px;
}

#webl-control-panel-gallery-container {
	margin-top: 10px;
}

#webl-control-panel-gallery {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	gap: calc((100% - 3 * 280px) / 2);
	justify-content: flex-start;
}

#webl-control-panel-gallery-filters-container {
	display: flex;
}

#webl-control-panel-gallery-filters-container button {
	height: 42px;
	width: auto;
	background-color: white;
	border: none;
	font-weight: bold;
	font-size: 18px;
	margin-right: 20px;
	padding: 8px 15px;
	display: flex;
	cursor: pointer;
}

#webl-control-panel-gallery-filters-container button :first-child {
	margin-right: 10px;
}

.webl-control-panel-gallery-item {
	display: flex;
	width: calc(100% / 3 - 10px);
	max-width: 280px;
	min-width: 280px;
	max-height: 360px;
	min-height: 280px;
	flex-direction: column;
	margin: 30px 0px;
	justify-content: space-between;
	flex: 0 1 calc(100% / 3);
}

.webl-control-panel-gallery-item-top-container,
.webl-control-panel-gallery-item-bottom-container {
	display: flex;
	width: 100%;
	justify-content: space-between;
	align-items: center;
	height: 30px;
	font-size: 18px;
}

.webl-control-panel-gallery-item-preview-container {
	display: flex;
	height: 100%;
	width: 100%;
	margin: 10px 0px;
	cursor: pointer;
	overflow: hidden;
	border: 1px solid var(--webl_lightgray);
	text-align: center;
	justify-content: center;
}

.webl-control-panel-gallery-item-preview-container img {
	width: auto;
	height: 100%;
}

.webl-control-panel-gallery-item-actions {
	display: flex;
	width: 150px;
	justify-content: space-between;
}

.webl-control-panel-gallery-item-actions img {
	width: 30px;
	height: 30px;
	cursor: pointer;
}

.webl-control-panel-gallery-item-file-name,
.webl-control-panel-gallery-item-status,
.webl-control-panel-gallery-item-date {
	font-weight: bold;
}

.webl-control-panel-gallery-item-date,
.webl-control-panel-gallery-item-status {
	font-size: 14px;
}

.webl-control-panel-gallery-item-file-name {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

#webl-control-panel-text-description-container > button > span {
	display: flex;
	white-space: pre-wrap;
	color: white;
}

@media screen and (max-width: 500px) {
	#webl-control-panel-text-description-container > button > span,
	#webl-control-panel-text-description-container > button > img {
		display: none;
	}

	#webl-control-panel-text-description-container > button {
		text-transform: capitalize;
		width: auto;
		padding: 5px 15px;
	}
}

#webl-control-panel-table-container .rc-scrollbars-track-h {
	bottom: 0px !important;
	right: 0px !important;
	left: 0px !important;
}

@media screen and (max-width: 600px) {
	#webl-control-panel-text-description-container > button > span,
	#webl-control-panel-text-description-container > button > img {
		display: none;
	}

	#webl-control-panel-text-description-container > button {
		text-transform: capitalize;
		width: auto;
		padding: 5px 15px;
	}
}

@media screen and (max-width: 965px) {
	.webl-control-panel-gallery-item {
		display: flex;
		width: calc(100% / 2 - 10px);
		max-width: none;
		min-width: 0px;
		max-height: none;
		min-height: 0px;
		flex-direction: column;
		margin: 30px 0px;
		justify-content: space-between;
		flex: 0 1 calc(100% / 2 - 10px);
	}

	#webl-control-panel-gallery {
		gap: 20px;
	}
}

@media screen and (max-width: 665px) {
	.webl-control-panel-gallery-item {
		display: flex;
		width: calc(100%);
		max-width: none;
		min-width: 0px;
		max-height: none;
		min-height: 0px;
		flex-direction: column;
		margin: 30px 0px;
		justify-content: space-between;
		flex: 0 1 calc(100%);
	}

	#webl-control-panel-gallery {
		gap: 20px;
	}
}

@media screen and (max-width: 365px) {
	.webl-control-panel-gallery-item {
		display: flex;
		width: 100%;
		flex-direction: column;
		margin: 30px 0px;
		justify-content: space-between;
		flex: 0 1 100%;
	}

	#webl-control-panel-gallery {
		gap: 20px;
	}
}
