@tailwind base;
@tailwind components;
@tailwind utilities;

* {
	margin: 0;
	box-sizing: border-box;
	font-family: "Arial Nova", sans-serif;
	color: var(--webl_black);
}

:root {
	--webl_black: #333333;
	--webl_green: #395643;
	--webl_lightgray: #d5d5d5;
	--webl_yellow: #d8df21;
	--webl_lightgreen: #8cc63f;
	--page_padding: 32px;
}

a,
a:visited,
a:hover,
a:active {
	color: inherit;
}

@font-face {
	font-family: "Arial Nova";
	src: url("../fonts/Arial Nova/ArialNova.ttf") format("truetype");
}

@font-face {
	font-family: "Arial Nova";
	src: url("../fonts/Arial Nova/ArialNova-Bold.ttf") format("truetype");
	font-weight: bold;
}

@font-face {
	font-family: "Arial Nova";
	src: url("../fonts/Arial Nova/ArialNova-Italic.ttf") format("truetype");
	font-style: italic;
}

body {
	margin: 0;
	background: #fff;
	color: var(--webl_black);
	-webkit-font-smoothing: antialiased;
	font-family: "Arial Nova";
}

/* hide increase/decrease arrows from amount input */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Footer.jsx */
footer {
	position: fixed;
	display: flex;
	align-items: center;
	justify-content: center;
	left: 0;
	bottom: 0;
	width: 100%;
	background-color: var(--alma_blue);
	color: white;
}

.page-information-container {
	width: 100%;
	text-align: center;
	align-items: center;
	justify-content: center;
	margin-bottom: 30px;
}

.page-title {
	display: flex;
	align-items: center;
	justify-content: center;
	padding-top: 30px;
}

.password-submit-button {
	background-color: var(--alma_blue) !important;
	color: white;
	float: right;
}

#luckysheet-info,
#luckysheet-modal-dialog-mask {
	display: none;
	visibility: hidden;
}

#luckysheet-modal-dialog-activeImage {
	pointer-events: none;
}

.MuiInputBase-root {
	border-radius: 0px !important;
}

.custom-dialog {
	width: 100vw;
	height: 100vh;
	left: 0 !important;
	top: 0 !important;
	z-index: 1051;
	/* because from pictures from the webl page come from aspose with higher z-index */
	position: fixed !important;
	display: flex !important;
	-webkit-box-align: center;
	align-items: center;
	-webkit-box-pack: center;
	justify-content: center !important;
	inset: 0px;
	background-color: rgba(0, 0, 0, 0.5);
	-webkit-tap-highlight-color: transparent;
}

.custom-dialog-content {
	display: flex !important;
	background-color: white;
	padding: 30px !important;
	align-items: center;
	animation: fadeInAnimation 0.2s;
	width: 515px;
	border-radius: 12px;
}

.rc-scrollbars-track {
	border: 1px solid var(--webl_black);
	border-radius: 0px !important;
}

.rc-scrollbars-track>div {
	background-color: var(--webl_black) !important;
}

.full-page-animation-container {
	position: fixed;
	width: 100vw;
	height: 100vh;
	left: 0;
	top: 0;
	background-color: white;
	z-index: 10000;
}

#webl-wrapper {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.logout-button {
	display: flex;
	align-items: center;
	cursor: pointer;
}

.logout-button>span {
	margin-right: 10px;
	font-size: 10px;
	font-weight: bold;
	text-wrap: nowrap;
}

#home-top-main-container>.logout-button {
	margin-left: 10px;
}

table[fit_width="true"] {
	width: max-content !important;
}

@media screen and (max-width: 400px) {
	#home-top-main-container {
		flex-direction: column-reverse;
	}

	#home-top-main-container>.logout-button {
		margin: 0px auto 10px 0px;
	}
}

#luckysheet-tooltip-up {
	display: none;
}

#error-container {
	height: 100%;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: calc(var(--page_padding) / 2);
}

.error-title {
	font-size: 24px;
	font-weight: bold;
	margin-right: 10px;
}

#log-in-verification {
	cursor: pointer;
}