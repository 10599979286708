#themes-container {
	display: flex;
	position: absolute;
	background-color: #e6e6e6;
	font-size: 13px;
	z-index: 1050;
	padding: 30px;
	margin-top: 1px;
}

#tabs-container {
	display: flex;
	font-size: 13px;
	z-index: 1050;
	margin-top: 30px;
}
#tabs-left {
	display: flex;
	flex-direction: column;
	height: auto;
	width: 250px;
	justify-content: space-between;
	padding-right: 30px;
}

#tabs-right {
	display: flex;
	flex-direction: column;
	height: 100%;
	width: 280px;
}
#themes-container-left {
	display: flex;
	flex-direction: column;
	height: auto;
	width: 250px;
	justify-content: space-between;
	padding-right: 30px;
}

#themes-container-right {
	display: flex;
	flex-direction: column;
	height: 100%;
	width: 280px;
}

#themes-list {
	display: flex;
	flex-wrap: wrap;
	gap: 20px;
}

#themes-list > div {
	width: 80px;
	height: 100px;
	cursor: pointer;
}

#themes-list > div > img {
	border: 3px solid transparent;
}

#themes-list .theme-icon {
	width: 100%;
}

#themes-buttons > * {
	text-align: center !important;
}

.theme-title {
	font-size: 11px;
	text-align: center;
}

#themes-switches-container > div > div {
	display: flex;
	align-items: center;
	border-bottom: 1px solid var(--webl_black);
	height: 40px;
	margin: 0px !important;
}

#themes-switches-container > div > div:last-child {
	border: none;
}

#themes-footnote-container {
	display: block;
	width: 100%;
	overflow-wrap: break-word;
}

#title-container {
	font-weight: bold;
	font-size: 18px;
}

#selected-theme-container {
	font-size: 18px;
}

#initial-container {
	display: flex;
	flex-direction: row;
}

#initial-container {
	margin-top: 20px;
}

#styles-container img {
	width: 80px;
	background-color: #e6e6e6;
	cursor: pointer;
	box-sizing: border-box;
}

#switches-containter {
	margin-left: 20px;
}

#customize-container {
	font-weight: bold;
}

.switch {
	margin-top: 9px;
	display: flex;
	flex-direction: row;
}

.switch > span {
	margin-left: 10px;
}

#secondary-themes {
	margin-top: 20px;
}

#first-row,
#second-row {
	display: flex;
	flex-direction: row;
}

#first-row > div:nth-child(-n + 3),
#second-row > div:nth-child(-n + 3) {
	margin-right: 20px;
}

#second-row {
	margin-top: 20px;
}

.theme_previews {
	display: none;
	position: absolute;
	top: 290px;
	left: 1050px;
	z-index: 1501;
	width: 400px;
	box-shadow: -5px 5px 5px lightgrey;
	background-color: white;
}

#initial-img-title {
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
}

#initial {
	margin-bottom: 3px;
}

#initial-title {
	font-size: 10px;
	font-weight: bold;
	text-align: center;
}

.selected-theme {
	border: 3px solid var(--webl_lightgreen) !important;
}
