.loading-container {
	display: flex;
	width: 100%;
	height: 100%;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	display: flex;
	flex: 1;
	align-items: center;
	margin-top: var(--page_padding);
}

.loading-inner {
	width: auto;
	height: auto;
	display: flex;
	overflow: hidden;
	justify-content: center;
	align-items: center;
	margin-bottom: 30px;
}

.loading-spinner {
	width: 120px;
	height: 24px;
	display: flex;
}

.loading-spinner div {
	position: absolute;
	width: 24px;
	height: 24px;
	transform: translate(0, 0) scale(1);
	animation: spin 1.8115942029s infinite cubic-bezier(0, 0.5, 0.5, 1);
}

.loading-spinner div:nth-child(2) {
	animation-delay: -0.3623188405797101s;
}

.loading-spinner div:nth-child(3) {
	animation-delay: -0.7246376811594202s;
}

.loading-spinner div:nth-child(4) {
	animation-delay: -1.0869565217391304s;
}

.loading-spinner div:nth-child(5) {
	animation-delay: -1.4492753623188404s;
}

.loading-spinner div:nth-child(6) {
	animation-delay: -1.8115942029s;
}

@keyframes spin {
	0% {
		transform: translate(0, 0) scale(0);
		background-color: rgb(57, 86, 67, 0.4);
	}

	10% {
		transform: translate(0, 0) scale(0.5);
		background-color: rgb(57, 86, 67, 0.4);
	}

	30% {
		transform: translate(20px, 0) scale(0.75);
		background-color: rgb(57, 86, 67, 0.8);
	}

	50% {
		transform: translate(48px, 0) scale(1);
		background-color: rgb(57, 86, 67, 1);
	}

	70% {
		transform: translate(76px, 0) scale(0.75);
		background-color: rgb(57, 86, 67, 0.8);
	}

	90% {
		transform: translate(96px, 0) scale(0.5);
		background-color: rgb(57, 86, 67, 0.4);
	}

	100% {
		transform: translate(96px, 0) scale(0);
		background-color: rgb(57, 86, 67, 0.4);
	}
}

.loading-text {
	text-align: center;
	line-height: 30px;
}
