#webl-tabs-container {
	display: flex;
	flex-wrap: nowrap;
	padding-left: var(--page_padding);
	border-bottom: 1px solid var(--webl_black);
	margin-right: 14px;
	min-width: 100% !important;
}

#webl-creation-container {
	display: flex;
	margin-right: var(--page_padding);
	border-right: 1px solid var(--webl_black);
	height: calc(100vh - var(--page_padding));
}

#left-container {
	width: calc(100% - 149px);
	border-top: 1px solid var(--webl_black);
}

#webl-title {
	border: none;
	font-size: 18px;
	width: 500px;
	padding-left: calc(var(--page_padding) / 4 - 1px);
	display: flex;
	height: 100%;
	align-items: center;
	justify-content: center;
	padding-bottom: 2px;
}

#webl-title:focus {
	outline: none !important;
	background-color: #f2f2f2;
}

.tab-button {
	display: flex;
	justify-content: flex-start;
	flex-direction: column;
	cursor: pointer;
	margin-top: 5px;
	width: 70px;
	flex-wrap: wrap;
}

.tab-button:active {
	display: flex;
	justify-content: flex-start;
	flex-direction: column;
	background-color: #f3f2f1;
	border-radius: 5px;
}

.tab-button-selected {
	display: flex;
	justify-content: flex-start;
	flex-direction: column;
	background-color: #f0f0f0;
	border-radius: 5px;
	margin-top: 5px;
}

.theme-button {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 51px;
	width: 85px;
	cursor: pointer;
}

.theme-list-container {
	margin-top: 5px !important;
}

.theme-button-icon {
	height: 24px;
}

.theme-card {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top: 10px;
}

.theme-preview-image {
	height: 370px;
	width: 545px;
}

.tab-button-icon {
	width: 100% !important;
	color: var(--alma_blue) !important;
}

.tab-button-caption {
	width: 100% !important;
	font-size: 0.7rem !important;
	color: var(--alma_blue) !important;
	font-family: "Arial Nova" !important;
}

.tab-description {
	display: flex;
	margin-top: 10px;
	justify-content: center;
}

.section-divider {
	height: 120px !important;
	margin: auto !important;
	background-color: var(--alma_blue);
}

.title-textfield-container {
	margin-top: 20px;
	width: calc(100% - 450px - 25px);
	margin-right: 25px;
}

.switches-container {
	display: flex;
	flex-wrap: wrap;
	height: 100px;
	width: 450px;
}
.tab-switch {
	display: flex !important;
	width: 240px;
}

.switches-label {
	font-family: "Arial Nova" !important;
}

.create-webl-button {
	display: flex;
	align-items: center;
	flex-direction: row;
	background-color: var(--alma_blue);
	border-radius: 5px;
	height: 70px;
	width: 100px;
	cursor: pointer;
	justify-content: space-between;
}

.create-webl-button > *,
.copy-embed-code-button > * {
	width: 100px;
	flex-shrink: inherit !important;
	text-align: left !important;
	padding: 0px !important;
}

.create-webl-button > * {
	color: white !important;
}

.copy-embed-code-button > * {
	color: var(--alma_blue) !important;
}
.create-webl-button:hover,
.copy-embed-code-button:hover {
	box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.create-webl-button-caption,
.copy-embed-code-button-caption {
	width: 85px;
	font-size: 11px !important;
	font-family: "Arial Nova" !important;
	padding-left: 10px !important;
}

.create-webl-button-caption {
	color: white !important;
}
.copy-embed-code-button-caption {
	color: var(--alma_blue) !important;
	font-weight: bold !important;
}

.copy-embed-code-button {
	display: flex;
	align-items: center;
	flex-direction: row;
	background-color: white;
	border: 2px solid var(--alma_blue);
	border-radius: 5px;
	height: var(--page_padding);
	width: 100px;
	cursor: pointer;
	margin-top: 5px;
	justify-content: space-between;
}

.webl-main-container {
	display: flex;
	flex-wrap: nowrap;
	width: 100%;
}

.spreadsheet-container {
	width: calc(100% - 150px - var(--page_padding));
	height: calc(100vh - 3 * var(--page_padding) - 3px);
	position: absolute;
}

.luckysheet {
	width: 100% !important;
	height: 100% !important;
}

#buttons-selected-cells-display {
	position: absolute;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	right: 0px;
	z-index: 1010;
	background-color: white;
	border-top: 1px solid var(--webl_black);
	padding-left: 4px;
	width: calc(150px + var(--page_padding));
	text-align: center;
}

.three-buttons-container {
	border-right: 1px solid var(--webl_black);
	padding-right: 5px !important;
	padding-top: 5px !important;
}

#selected-cells-display {
	/* 
	var(--page_padding)  from the  div with id header-container,
 	165px from the div with class three-buttons-container, 
	5px   from the div with id buttons-selected-cells-display
	*/
	height: calc(100vh - var(--page_padding) - 195px);
	overflow-y: auto;
	padding: 0px !important;
	width: 146px;
	border-right: 1px solid var(--webl_black);
	padding-right: 5px !important;
}

#selected-cells-display > * > * > * {
	color: #333333 !important;
}

#selected-cells-display > * > * > * > * {
	color: #333333 !important;
}

.title-available {
	color: var(--alma_blue);
	text-align: left;
}

.input-formula-container {
	float: right;
	font-size: 20px;
	padding: 4px;
	font-weight: bold;
	letter-spacing: 1px;
	max-width: 300px;
}

.copy-code-dialog-create-button > * {
	padding: 8px 0px 8px 16px !important;
}
