.holpDy,
.iGPiVp {
	border: 2px solid var(--webl_black) !important;
}
.iGPiVp:before,
.holpDy:before {
	background-color: white !important;
	border: 2px solid var(--webl_black) !important;
}
#webl-creation-container .iGPiVp:before,
#webl-creation-container .holpDy:before {
	width: 5px !important;
	height: 5px !important;
}
.switch-container > span > label > label > .iGPiVp,
.switch > label > label > .iGPiVp,
.switch-container > span > label > label > .holpDy,
.switch > label > label > .holpDy {
	border: 1px solid var(--webl_black) !important;
}
.switch-container > span > label > label > .iGPiVp:before,
.switch
	> label
	> label
	> .iGPiVp:before
	.switch-container
	> span
	> label
	> label
	> .holpDy:before,
.switch > label > label > .holpDy:before {
	border: 1px solid var(--webl_black) !important;
	left: 3px;
}
