.custom-confirmation-dialog-content {
	display: flex !important;
	flex-direction: column;
	min-width: 470px;
	font-size: 18px;
	align-items: normal !important;
}

.custom-confirmation-dialog-content > button {
	margin-top: 10px;
	cursor: pointer;
	border-radius: 4px;
}
.confirmation-buttons {
	display: flex;
	width: 100%;
	margin-top: 30px;
	justify-content: flex-end !important;
	padding: 0px !important;
	gap: 10px;
}

.cancel_btn,
.confirm_btn {
	cursor: pointer;
	border: none;
	border-radius: 8px;
	font-size: 16px;
	width: 110px;
	padding: 10px;
	transition: background-color 0.3s;
	background-color: var(--webl_black);
	color: #fff;
}

.cancel_btn:hover,
.confirm_btn:hover {
	background-color: var(--webl_green);
	color: #fff;
}
