#webl-title-container {
	display: flex;
	align-items: center;
	height: var(--page_padding);
	width: 50%;
	border-right: 1px solid var(--webl_black);
	padding-right: 10px;
}

#webl-domain-container {
	height: 32px;
	display: flex;
}

#webl-title,
#webl-domain {
	border: none;
	font-size: 18px;
	width: 400px;
	padding-left: calc(var(--page_padding) / 4 - 1px);
	display: flex;
	height: 100%;
	align-items: center;
	justify-content: center;
	padding-bottom: 2px;
}

#webl-title:focus,
#webl-domain:focus {
	outline: none !important;
	background-color: #f2f2f2;
}

#inputs-container > .nFmaW {
	display: inline-flex;
	margin-left: 10px;
}

#inputs-container {
	margin-left: var(--page_padding);
	border-bottom: 1px solid var(--webl_black);
	border-left: 1px solid var(--webl_black);
	margin-right: 14px;
	display: flex;
	min-width: 100%;
}

#webl-title-availability,
#webl-domain-availability {
	display: flex;
	align-items: center;
	visibility: hidden;
	margin-left: 10px;
}

.title-unavailable {
	color: var(--alma_red);
	text-align: left;
	font-size: 13px;
	margin-left: 10px;
	font-weight: bold;
}

#no-display-title {
	margin-left: 10px;
	font-size: 13px;
}

#webl-title-display {
	display: flex;
	margin-left: 10px;
}
