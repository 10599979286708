/* ALMA THEME */

/* Font*/
@font-face {
	font-family: "Gilroy";
	src: url("../../fonts/Gilroy/Gilroy.ttf") format("truetype");
	font-weight: normal;
}

.webl-html[webl_theme="Alma"] {
	background-color: transparent !important;
	display: inline-block;
}

.webl-html table[webl_theme="Alma"] {
	border-spacing: 6px !important;
}

/* Text Cells */
.webl-html table td[webl_theme="Alma"],
.webl-html table td[webl_theme="Alma"] select,
.webl-html table td[webl_theme="Alma"] a span,
.webl-html table td[webl_theme="Alma"] select option {
	font-family: "Gilroy" !important;
	background: #ffffff !important;
	outline: solid 1px #3b4559 !important;
	border-radius: 3px;
	color: #3b4559 !important;
	padding: 3px 6px !important;
}

/* Input Cells */
.webl-html table td[contenteditable][webl_theme="Alma"] {
	background-color: #f2f2f2 !important;
	outline: solid 1px #ffe600 !important;
	-webkit-border-radius: 3px !important;
	-moz-border-radius: 3px !important;
	border-radius: 3px !important;
	color: #3b4559 !important;
	font-weight: normal !important;
}

/* Sum Cells */
.webl-html table td[x\:fmla][webl_theme="Alma"] {
	background-color: #ffe600 !important;
	outline: solid 2px #ffe600 !important;
	-webkit-border-radius: 3px !important;
	-moz-border-radius: 3px !important;
	border-radius: 3px !important;
	color: #3b4559 !important;
	font-weight: bold !important;
}

/* Empty Cells */
.webl-html table td[webl_theme="Alma"]:empty {
	background-color: #ffffff !important;
	box-shadow: none !important;
	border: none !important;
	outline: 0px !important;
}

/* Aditional Properties */
.webl-html table[table_spacing="true"][webl_theme="Alma"] {
	border-collapse: unset;
	border-spacing: 5px !important;
	padding: 0px 5px;
}

.webl-html td[gridlines="true"][webl_theme="Alma"],
.webl-html td[gridlines="true"][webl_theme="Alma"]:empty {
	border: 1px solid #cccccc !important;
	border-collapse: collapse;
	padding: 0px 5px;
}

.webl-html td[contenteditable][input_borders="true"][webl_theme="Alma"] {
	outline: #e8666d auto 1px !important;
	padding: 0px 5px;
}

.webl-html td[round_borders="true"][webl_theme="Alma"] {
	border-radius: 5px !important;
	padding: 0px 5px;
}
