#home-container {
	display: flex;
	flex-direction: column;
	height: auto;
}

#home-outline-top {
	width: 100%;
	height: var(--page_padding);
	border-top: 1px solid var(--webl_black);
	border-bottom: 1px solid var(--webl_black);
	display: flex;
}

#home-outline-top-left,
#home-outline-top-right {
	width: calc(var(--page_padding) + 1px);
}

#home-outline-top-right {
	border-left: 1px solid var(--webl_black);
	margin-left: auto;
}

#home-outline-top-left {
	border-right: 1px solid var(--webl_black);
	margin-right: auto;
}

#home-outline-top-left img {
	width: auto;
	height: 100%;
}

#home-container-content {
	width: calc(100% - calc(var(--page_padding) * 2));
	min-height: calc(100vh - 160px);
	display: flex;
	flex-direction: column;
	margin: 0px auto;
	padding: 0px calc(((100% - 1200px) / 2));
	border: 1px solid var(--webl_black);
	border-width: 0 1px 1px 1px;
}

#home-container-content > div {
	display: flex;
	background-color: white;
	width: 100%;
	margin: 20px 0px;
	flex-direction: column;
}

#home-top-main-container {
	display: flex;
	width: 100%;
	justify-content: space-between;
	align-items: flex-start;
}

#home-top-main-container > span {
	font-size: 21px;
	font-weight: bold;
}

#home-top-switch-container {
	display: flex;
	margin-left: auto;
	font-weight: bold;
	font-size: 12px;
}

#home-top-logout-button-container {
	display: flex;
	margin-left: auto;
}

@media only screen and (max-width: 1000px) {
	#home-container-content > div {
		padding-left: 10px;
		padding-right: 10px;
	}
}

#home-top-buttons-container {
	display: flex;
	gap: calc(var(--page_padding) / 2);
}

.manage-account-option {
	text-decoration: none;
}

.manage-account-option:hover {
	text-decoration: underline;
	cursor: pointer;
}

#account-subdomain-field-container {
	position: relative;
	height: 40px;
	width: 300px;
	overflow: hidden;
	display: flex;
	flex-direction: row;
	border: 1px solid var(--webl_black);
	margin: 15px 0px 0px 15px;
}

#account-subdomain-field-container .prefix {
	position: relative;
	height: auto;
	width: auto;
	display: flex;
	justify-content: center;
	align-content: center;
	flex-direction: column;
	background: rgb(243, 242, 241);
	color: rgb(96, 94, 92);
	display: flex;
	align-items: center;
	white-space: nowrap;
	flex-shrink: 0;
	padding: 0px 10px;
	box-sizing: border-box;
	text-decoration: none !important;
}

#account-subdomain-field-container input {
	position: right;
	margin: 0px;
	padding: 0px;
	height: 100%;
	width: 100%;
	padding-left: 10px;
	padding-right: 10px;
	background: none;
	border: none;
	outline: none;
	display: block;
	font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
	font-size: 16px;
	box-sizing: border-box;
}

.links > * {
	display: block;
	box-sizing: border-box;
	width: fit-content;
}

.links > * {
	margin-top: calc(var(--page_padding) / 2);
}
