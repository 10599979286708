/* Horizontal bar*/
/*  BAR INSIDE THE BOX*/
.inside_box > .rc-scrollbars-container > .rc-scrollbars-track-h {
	display: block;
	margin-bottom: 50px;
	margin-left: 270px;
	margin-right: 270px;
	border: 1px solid var(--webl_black);
	border-radius: 0px !important;
}
.inside_box > .rc-scrollbars-container > .rc-scrollbars-track-h > div {
	background-color: var(--webl_black) !important;
}
.inside_box > .rc-scrollbars-container > .rc-scrollbars-track-h {
	position: fixed !important;
	margin: 0px calc((100vw - 1000px) / 2 + 25px) 65px !important;
	bottom: calc(var(--page_padding)) !important;
}
/*  BAR OUTSIDE THE BOX*/
.outside_box > .rc-scrollbars-container > .rc-scrollbars-track-h {
	display: block;
	margin-bottom: 50px;
	margin-left: 270px;
	margin-right: 270px;
	border: 0 !important;
	border-radius: 0px !important;
}
.outside_box > .rc-scrollbars-container > .rc-scrollbars-track-h > div {
	background-color: lightgrey !important;
}
.outside_box > .rc-scrollbars-container > .rc-scrollbars-track-h {
	position: fixed !important;
	margin: 0px calc((100vw - 1000px) / 2 + 25px) 65px !important;
	bottom: calc(var(--page_padding) - 10px) !important;
	background-color: rgb(240, 240, 240);
	height: 15px !important;
}
/* Vertical bar*/
/*  BAR INSIDE THE BOX*/
.inside_box > .rc-scrollbars-container > .rc-scrollbars-track-v {
	margin: 10px 20px calc(var(--page_padding) * 2) 0px !important;
	border: 1px solid var(--webl_black);
	border-radius: 0px !important;
}
.inside_box > .rc-scrollbars-container > .rc-scrollbars-track-v > div {
	background-color: var(--webl_black) !important;
}
.inside_box > .rc-scrollbars-container > .rc-scrollbars-track-v {
	position: fixed !important;
	right: calc(var(--page_padding)) !important;
	top: calc(4 * var(--page_padding)) !important;
	margin-bottom: calc(4 * var(--page_padding)) !important;
}
/*  BAR OUTSIDE THE BOX*/
.outside_box > .rc-scrollbars-container > .rc-scrollbars-track-v {
	margin-top: 0 !important;
	margin-bottom: 0 !important;
	margin-left: 0 !important;
	margin-right: 20px !important;
	border: none !important;
	background-color: rgb(240, 240, 240);
	width: 15px !important;
	height: 100% !important;
}
.outside_box > .rc-scrollbars-container > .rc-scrollbars-track-v > div {
	/* background-color: var(--webl_black) !important; */
	background-color: lightgrey !important;
	width: 15px;
}
.outside_box > .rc-scrollbars-container > .rc-scrollbars-track-v {
	position: fixed !important;
	right: calc(var(--page_padding) - 50px) !important;
	top: calc(4 * var(--page_padding) - 64px) !important;
	margin-bottom: calc(4 * var(--page_padding)) !important;
	width: 15px;
}
@media only screen and (max-width: calc(1100px)) {
	#webl-container > .rc-scrollbars-container > .rc-scrollbars-track-h {
		width: calc(100vw - 2 * var(--page_padding) - 30px) !important;
		margin: 0px auto 65px !important;
	}
}

@media screen and (max-width: 1450px) {
	#webl-tabs-container,
	#inputs-container {
		margin-right: 0px;
	}
}
