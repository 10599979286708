#iframe-dialog-content {
	width: fit-content;
}
#iframe-input {
	width: 300px;
}
#iframe-copy-icon {
	height: 40px;
	border-left: none;
	border-top: 1px solid #c4c4c4;
	border-bottom: 1px solid #c4c4c4;
	border-right: 1px solid #c4c4c4;
}
#iframe-copy-icon-button {
	height: 40px;
	background: none;
	border: none;
	cursor: pointer;
	width: auto;
	margin: 0;
}

#iframe-height-input,
#iframe-width-input {
	width: 100px;
}

#iframe-height-input::placeholder,
#iframe-width-input::placeholder {
	font-size: 16px;
}
#create-iframe-button {
	height: 40px;
	margin: 0 0 0 10px;
	padding: 0;
}
