.side-panel-item {
	display: flex;
	height: auto;
	width: 100%;
	padding: 3px 16px;
	justify-content: space-between;
	align-items: center;
	font-weight: 400;
	line-height: 1.43;
	cursor: pointer;
	font-weight: bold;
}

.side-panel-item:hover {
	background-color: #f2f2f2 !important;
}

.selected-area-container {
	display: flex;
	flex-direction: column;
	margin-top: 20px;
	text-align: left;
	padding-right: 5px;
}

.delete-icon {
	width: 20px;
}

.selected-area-container > * > * {
	font-size: 13px;
}

.header-selected-area {
	position: sticky;
	top: 0;
	background-color: white;
	z-index: 1;
}

.side-panel-wrapper {
	flex: 1;
	overflow-y: auto;
}

.side-panel {
	max-height: 100px;
	overflow-y: auto;
}

hr {
	display: block;
	height: 1px;
	border: 0;
	border-top: 1px solid var(--webl_black);
	padding: 0;
	width: calc(100% - 20px);
	margin: 0 auto 5px;
}

.header-selected-area {
	width: 100%;
	display: inline-flex;
	padding-left: 16px;
	margin: 0;
	font-weight: 500;
	font-size: 0.875rem;
	line-height: 1.75;
	text-transform: uppercase;
}
.no-select-caption {
	margin-left: 15px;
	font-family: "Arial Nova", sans-serif;
	font-weight: 400;
	font-size: 0.75rem;
	line-height: 1.66;
}
.selected-area-container > div > span {
	margin-left: 15px;
}
