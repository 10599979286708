#home-header-container {
	width: calc(100% - calc(var(--page_padding) * 2));
	display: flex;
	margin: 0px auto;
	padding: 10px calc(((100% - 1200px) / 2));
	height: auto;
	justify-content: space-between;
	align-items: center;
	background-color: var(--webl_green);
}

#home-header-container-left,
#home-header-container-right {
	display: flex;
	width: auto;
	height: 100%;
}

#home-header-container-left > img,
#home-header-container-right > img {
	height: auto;
}

#home-header-container-left > img {
	width: 166px;
	padding: 2px 0px;
}

#home-header-container-right > img {
	width: 160px;
}

#home-header-container-left > img > * {
	color: red !important;
	fill: blue !important;
}

@media only screen and (max-width: 1000px) {
	#home-header-container-left,
	#home-header-container-right {
		margin: 0 10px;
	}
}

@media only screen and (max-width: 443px) {
	#home-header-container-left > img {
		width: 100%;
	}

	#home-header-container-right > img {
		width: 100%;
	}
}
