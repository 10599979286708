#webl-uploader-multiple-sheets-dialog .rc-scrollbars-track-h {
	display: none;
}

#home-page > .rc-scrollbars-container > .rc-scrollbars-track-v {
	margin: 120px 60px calc(var(--page_padding) * 2) 0px !important;
}

.MuiMenu-list > .rc-scrollbars-container > .rc-scrollbars-track-h {
	display: none;
}

.MuiMenu-list > .rc-scrollbars-container > .rc-scrollbars-track-v {
	margin: 10px 30px 10px 0px !important;
}

.MuiMenu-list :first-child {
	font-style: normal;
}

@media screen and (max-width: 1100px) {
	#home-page > .rc-scrollbars-container > .rc-scrollbars-track-v {
		margin: 120px calc(var(--page_padding) / 2 - 5px)
			calc(var(--page_padding) * 2) 0px !important;
	}
}
