/* CRT THEME */

@font-face {
	font-family: "Ubuntu Mono";
	src: url("../../fonts/Ubuntu Mono/UbuntuMono-Regular.ttf")
		format("truetype");
	font-weight: normal;
}

@font-face {
	font-family: "Ubuntu Mono";
	src: url("../../fonts/Ubuntu Mono/UbuntuMono-Bold.ttf") format("truetype");
	font-weight: bold;
}

@font-face {
	font-family: "Ubuntu Mono";
	src: url("../../fonts/Ubuntu Mono/UbuntuMono-Italic.ttf") format("truetype");
	font-style: italic;
}

.webl-html[webl_theme="CRT"] {
	background-color: transparent !important;
	display: inline-block;
}

.webl-html table[webl_theme="CRT"] {
	border-spacing: 0px 1px !important;
}

/* Text Cells */
.webl-html td[webl_theme="CRT"],
.webl-html td[webl_theme="CRT"] > *,
.webl-html td[webl_theme="CRT"] > select > option,
.webl-html td[webl_theme="CRT"] > a > span {
	font-family: "Ubuntu Mono" !important;
	color: #1a1a1a !important;
	border: 0px !important;
	background-color: #ffffff !important;
}
.webl-html td[webl_theme="CRT"] {
	border-radius: 0px !important;
	background: #ffffff !important;
	padding: 2px;
	box-shadow: 0 1px 0 #1a1a1a;
}

/* Input Cells */
.webl-html td[contenteditable][webl_theme="CRT"] {
	background-color: #00ff00 !important;
	border: 1px solid #e6e6e6 !important;
	border-bottom: none !important;
	border-radius: 0px !important;
	box-shadow: 0 1px 0 #1a1a1a;
	color: #000000 !important;
}

/* Sum Cells */
.webl-html td[x\:fmla][webl_theme="CRT"] {
	background-color: #0069b5 !important;
	border: 1px solid #e6e6e6 !important;
	border-bottom: none !important;
	border-radius: 0px !important;
	box-shadow: 0 1px 0 #1a1a1a;
	color: #ffffff !important;
}

/* Empty Cells */
.webl-html table td[webl_theme="CRT"]:empty {
	background-color: #ffffff !important;
	box-shadow: none !important;
	border: none !important;
	outline: 0px !important;
}

/* Aditional Properties */
.webl-html table[table_spacing="true"][webl_theme="CRT"] {
	border-collapse: unset;
	border-spacing: 5px !important;
	padding: 0px 5px;
}

.webl-html td[gridlines="true"][webl_theme="CRT"],
.webl-html td[gridlines="true"][webl_theme="CRT"]:empty {
	border: 1px solid #cccccc !important;
	border-collapse: collapse;
	padding: 0px 5px;
}

.webl-html td[contenteditable][input_borders="true"][webl_theme="CRT"] {
	outline: #f2f2f2 solid 1px !important;
	padding: 0px 5px;
}

.webl-html td[round_borders="true"][webl_theme="CRT"] {
	border-radius: 5px !important;
	padding: 0px 5px;
}
