#settings-container {
	position: absolute;
	background-color: #e6e6e6;
	font-size: 13px;
	z-index: 1050;
	width: 420px;
	padding: 30px;
	margin-top: 1px;
}

.switch-container {
	padding-top: 8px;
	padding-bottom: 8px;
	border-bottom: 1px solid #343739;
}

#settings-container :nth-last-child(2) {
	border-bottom: none !important;
}

#settings-table :first-child th {
	text-align: left;
	padding: 15px 20px 5px 20px;
}

.span-first-level {
	width: 100%;
	display: flex;
	align-items: center;
}

.span-second-level {
	margin-left: 10px;
	cursor: pointer;
}

#settings-table :last-child td {
	padding: 5px 20px 15px 20px;
}

#first-row {
	font-size: 18px;
	font-weight: bold;
}

#second-row {
	border-bottom: none;
	font-weight: bold;
}

#settings-container > .sc-bdnyFh {
	margin-left: 0px !important;
}

#open-user-settings {
	cursor: pointer;
	font-weight: bold;
	border-bottom: none !important;
	margin-top: 20px;
}

.info-tooltip {
	width: 15px;
	position: absolute;
	right: 30px;
}

.zoom-value-container {
	margin-left: 10px;
	min-width: 35px;
}

.zoom-slider-container > span {
	gap: 5px;
}
