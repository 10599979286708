#webl-content-wrapper {
	display: flex;
	width: calc(100% - calc(var(--page_padding) * 2));
	height: calc(100vh - 180px);
	border: 1px solid var(--webl_black);
}

#webl-content-mask {
	position: absolute;
	left: var(--page_padding);
	width: calc(100% - calc(var(--page_padding) * 2));
	height: calc(100vh - 180px);
	margin: 0 auto;
	border: 1px solid var(--webl_black);
}

#webl-fullscreen-wrapper {
	width: calc(100% - calc(var(--page_padding) * 2));
	height: auto;
	margin: 0 auto;
}

#webl-container {
	display: flex;
	position: relative;
	margin: 7px auto 0 7px;
	overflow: overlay;
}

.cont1 {
	width: 100vw !important;
	height: calc(100vh - 152px) !important;
	margin-top: 50px;
	position: relative;
	display: block;
	overflow: scroll;
}

.cont2 {
	width: inherit;
	height: inherit;
}

.webl-input-cell,
.webl-input-field,
.webl-text {
	height: 100%;
	width: 100%;
	cursor: text;
	font-size: 11pt;
	cursor: text;
	padding: 3px;
	text-align: center;
	border: 1px solid transparent;
}

.webl-input-field:focus br,
.webl-input-cell:focus br {
	display: none;
}

.webl-input-cell:focus,
.webl-input-field:focus {
	outline: none;
	display: inline;
	white-space: nowrap;
	display: none;
}

.webl-input-cell::placeholder,
.webl-input-field::placeholder {
	color: inherit;
}

.webl-text[disabled] {
	background-color: transparent;
	border: none;
}

.webl-input-field input {
	font-size: 40pt;
}

.webl-sheet-button {
	color: var(--alma_blue) !important;
	border: 1px solid var(--alma_blue) !important;
	margin: 0 20px !important;
	width: 130px;
}

.webl-sheet-button-selected {
	background-color: var(--alma_blue) !important;
	margin: 0 20px !important;
	width: 130px;
}

.chart-container {
	position: relative;
	height: 100%;
	width: 100%;
}

.webl-actions-container {
	text-align: center;
}

/* Because if a td has style of overflow:hidden means that the text in the cell even if it has white spaces it shouldn't count as new line*/
td:not([style*="overflow:hidden"]):not(.luckysheet-paneswrapper) {
	white-space: pre-line;
}

.webl-hidden-cell {
	visibility: hidden !important;
}

/* Empty Cells */
tr[is_hidden="True"] {
	display: none;
}
td[is_hidden="True"] {
	display: none;
}
col[is_hidden="True"] {
	display: none;
}

.webl-html table[table_spacing="true"][webl_theme="Initial"] {
	border-collapse: unset;
	border-spacing: 5px !important;
	padding: 0px 5px;
}

.webl-html td[gridlines="true"][webl_theme="Initial"],
.webl-html td[gridlines="true"][webl_theme="Initial"]:empty {
	border: 1px solid #ababab;
	border-collapse: collapse;
	padding: 0px 5px;
}

.webl-html td[contenteditable][input_borders="true"][webl_theme="Initial"] {
	outline: -webkit-focus-ring-color auto 1px !important;
	padding: 0px 5px;
}

.webl-html td[round_borders="true"][webl_theme="Initial"] {
	border-radius: 5px !important;
	padding: 0px 5px;
}
