/* SALMON THEME */

.webl-html[webl_theme="Salmon"] {
	background-color: transparent !important;
	display: inline-block;
}

.webl-html table[webl_theme="Salmon"] {
	border-spacing: 6px !important;
}

/* Text Cells */
.webl-html td[webl_theme="Salmon"],
.webl-html td[webl_theme="Salmon"] > *,
.webl-html td[webl_theme="Salmon"] > select > option,
.webl-html td[webl_theme="Salmon"],
.webl-html td[webl_theme="Salmon"] > a > span {
	font-family: Arial !important;
	color: #354559 !important;
	border: 0px;
}
.webl-html td[webl_theme="Salmon"] {
	border-radius: 3px !important;
	outline: 1px solid #808080 !important;
	background: #ffffff !important;
	padding: 3px 6px !important;
}

/* Input Cells */
.webl-html td[contenteditable][webl_theme="Salmon"] {
	background-color: #f2f2f2 !important;
	outline: 1px solid #e8666d !important;
	border: none !important;
	border-radius: 3px !important;
	color: #354559 !important;
}

/* Sum Cells */
.webl-html td[x\:fmla][webl_theme="Salmon"] {
	background-color: #e8666d !important;
	outline: 1px solid #cccccc !important;
	border: none !important;
	border-radius: 3px !important;
	color: #ffffff !important;
	font-weight: bold !important;
}

/* Empty Cells */
.webl-html table td[webl_theme="Salmon"]:empty {
	background-color: white !important;
	outline: none !important;
}

/* Aditional Properties */
.webl-html table[table_spacing="true"][webl_theme="Salmon"] {
	border-collapse: unset;
	border-spacing: 5px !important;
	padding: 0px 5px;
}

.webl-html td[gridlines="true"][webl_theme="Salmon"],
.webl-html td[gridlines="true"][webl_theme="Salmon"]:empty {
	border: 1px solid #2f2f2f !important;
	border-collapse: collapse;
	padding: 0px 5px;
}

.webl-html td[contenteditable][input_borders="true"][webl_theme="Salmon"] {
	outline: #e8666d auto 1px !important;
	padding: 0px 5px;
}

.webl-html td[round_borders="true"][webl_theme="Salmon"] {
	border-radius: 5px !important;
	padding: 0px 5px;
}
