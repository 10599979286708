/* HALITE BLUE THEME */

/* Font*/
@font-face {
	font-family: "Montserrat";
	src: url("../../fonts/Montserrat/Montserrat.ttf") format("truetype");
	font-weight: normal;
}
@font-face {
	font-family: "Montserrat";
	src: url("../../fonts/Montserrat/Montserrat-Bold.ttf") format("truetype");
	font-weight: bold;
}
@font-face {
	font-family: "Montserrat";
	src: url("../../fonts/Montserrat/Montserrat-Italic.ttf") format("truetype");
	font-style: italic;
}

.webl-html[webl_theme="Halite Blue"] {
	background-color: transparent !important;
	display: inline-block;
}

.webl-html table[webl_theme="Halite Blue"] {
	border-spacing: 1px !important;
}
/* Text Cells */
.webl-html table td[webl_theme="Halite Blue"],
.webl-html table td[webl_theme="Halite Blue"] select,
.webl-html table td[webl_theme="Halite Blue"] a span,
.webl-html table td[webl_theme="Halite Blue"] select option {
	font-family: "Montserrat" !important;
	background: #ffffff !important;
	outline: solid 1px #e6e6e6 !important;
	-webkit-border-radius: 0 !important;
	-moz-border-radius: 0 !important;
	border-radius: 0 !important;
	color: #0a3543 !important;
	padding: 3px 6px !important;
	font-weight: bold;
}

/* Input Cells */
.webl-html table td[contenteditable][webl_theme="Halite Blue"] {
	background-color: #e6e6e6 !important;
	border: 1px solid #0a3542 !important;
	-webkit-border-radius: 0 !important;
	-moz-border-radius: 0 !important;
	border-radius: 0 !important;
	color: #0a3542 !important;
	font-style: italic !important;
}
/* Sum Cells */
.webl-html table td[x\:fmla][webl_theme="Halite Blue"] {
	font-style: normal !important;
	background-color: #507282 !important;
	outline: solid 1px #0a3542 !important;
	-webkit-border-radius: 0 !important;
	-moz-border-radius: 0 !important;
	border-radius: 0 !important;
	color: #ffffff !important;
}

/* Empty Cells */
.webl-html table td[webl_theme="Halite Blue"]:empty {
	background-color: white !important;
	outline: solid 1px #e6e6e6 !important;
}

/* Aditional Properties */
.webl-html table[table_spacing="true"][webl_theme="Halite Blue"] {
	border-collapse: unset;
	border-spacing: 5px !important;
	padding: 0px 5px;
}

.webl-html td[gridlines="true"][webl_theme="Halite Blue"],
.webl-html td[gridlines="true"][webl_theme="Halite Blue"]:empty {
	border: 1px solid #f2f2f2;
	border-collapse: collapse;
	padding: 0px 5px;
}

.webl-html td[contenteditable][input_borders="true"][webl_theme="Halite Blue"] {
	outline: -webkit-focus-ring-color auto 1px !important;
	padding: 0px 5px;
}

.webl-html td[round_borders="true"][webl_theme="Halite Blue"] {
	border-radius: 5px !important;
	padding: 0px 5px;
}
