#home-footer-container {
	width: calc(100% - calc(var(--page_padding) * 2));
	display: flex;
	margin: 0px auto;
	padding: 10px calc(((100% - 1000px) / 2));
	align-items: center;
}

#home-footer-container > *:first-child {
	margin-right: auto;
}

#home-footer-container > *:last-child {
	margin-left: auto;
}

#home-footer-container > * {
	font-size: 13px;
	text-decoration: none;
}
