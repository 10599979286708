#publish-webl-button {
  height: calc(var(--page_padding) * 2);
  font-size: 13px;
  background-color: #fcee21;
  font-weight: bold;
  display: flex; /* Use flexbox for content alignment */
  flex-direction: column; /* Stack the icon and text vertically */
  justify-content: center; /* Center content vertically */
  align-items: center; /* Center content horizontally */
  text-align: center; /* Ensure text is centered */
  padding: 10px; /* Add some padding for better visuals */
}

#publish-webl-button img {
  width: 30px !important; /* Keep the icon size consistent */
  margin-bottom: 5px; /* Add space between the icon and text */
}

.three-buttons-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.three-buttons-container img {
  width: 25px;
}

.secondary-button {
  background-color: white;
  font-weight: bold;
  display: flex;
  align-items: center;
  height: 35px;
  padding-left: 10px;
  padding-right: 10px;
  justify-content: space-between;
  font-size: 13px;
}

.three-buttons-container button {
  width: 140px;
  margin-bottom: 5px;
  cursor: pointer;
  border: 2px solid var(--webl_black);
}

.three-buttons-container > * {
  color: #333333;
}

.three-buttons-container > * > * {
  color: #333333;
}
