/* LEVANDER THEME */

.webl-html[webl_theme="Alma"] {
	background-color: transparent !important;
	display: inline-block;
}

.webl-html table[webl_theme="Levander"] {
	border-spacing: 0px 1px !important;
}

/* Text Cells */
.webl-html td[webl_theme="Levander"],
.webl-html td[webl_theme="Levander"] > *,
.webl-html td[webl_theme="Levander"] > select > option,
.webl-html td[webl_theme="Levander"] > a > span {
	font-family: Arial !important;
	color: #1a1a1a !important;
	border: 0px !important;
	background-color: transparent !important;
	outline: -webkit-focus-ring-color auto 0px !important;
	padding: 3px 6px !important;
}
.webl-html td[webl_theme="Levander"] {
	border-radius: 0px !important;
	box-shadow: 0 1px 0 #6154a4;
	background: #ffffff !important;
}
/* Input Cells */
.webl-html td[contenteditable][webl_theme="Levander"] {
	background-color: #e6e6e6 !important;
	outline: 1px solid #6154a4 !important;
	border: none !important;
	border-radius: 0px !important;
	color: #6154a4 !important;
}

/* Sum Cells */
.webl-html td[x\:fmla][webl_theme="Levander"] {
	background-color: #e6e6e6 !important;
	outline: 1px solid #6154a4 !important;
	border: none !important;
	border-radius: 0px !important;
	color: #1a1a1a !important;
	font-weight: bold !important;
}

/* Empty Cells */
.webl-html table td[webl_theme="Levander"]:empty {
	background-color: #ffffff !important;
	box-shadow: none !important;
	border: none !important;
	outline: 0px !important;
}

/* Aditional Properties */
.webl-html table[table_spacing="true"][webl_theme="Levander"] {
	border-collapse: unset;
	border-spacing: 5px !important;
	padding: 0px 5px;
}

.webl-html td[gridlines="true"][webl_theme="Levander"],
.webl-html td[gridlines="true"][webl_theme="Levander"]:empty {
	border: 1px solid #cccccc !important;
	border-collapse: collapse;
	padding: 0px 5px;
}
.webl-html td[contenteditable][input_borders="true"][webl_theme="Levander"] {
	outline: #6154a4 solid 2px !important;
	padding: 0px 5px;
}

.webl-html td[round_borders="true"][webl_theme="Levander"] {
	border-radius: 5px !important;
	padding: 0px 5px;
}
