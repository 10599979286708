.custom-title {
	font-size: 50px;
	font-weight: bold;
	letter-spacing: -2px;
	line-height: 60px;
}

.custom-subtitle {
	font-size: 18px;
	font-weight: normal;
	height: 45px;
	display: flex;
	align-items: center;
}

@media screen and (max-width: 600px) {
	.custom-subtitle {
		height: auto;
	}
}
