#webl-manager-left-item,
#webl-manager-right-item {
	display: flex;
	height: 100%;
	width: calc(var(--page_padding) + 1px);
}

#webl-manager-left-item {
	border-right: 1px solid var(--webl_black);
	margin-right: auto;
}

#webl-manager-right-item {
	border-left: 1px solid var(--webl_black);
	margin-left: auto;
}

.webl-manager-container {
	margin: 0 auto;
	display: flex;
	height: var(--page_padding);
	width: 100%;
	align-items: center;
	justify-content: space-between;
}

#webl-manager-inner-container {
	display: flex;
	background-color: var(--webl_green);
	width: calc(100% - var(--page_padding) * 2 - 2px);
	justify-content: space-between;
	align-items: center;
}

.sheet-tabs-container {
	display: flex;
	flex-direction: row;
	width: 100%;
	overflow-x: hidden;
	height: var(--page_padding);
}

.sheet-tabs-modal {
	position: absolute;
	width: auto;
	height: auto;
	display: flex;
	flex-direction: column;
	z-index: 2000;
	top: calc(var(--page_padding) * 3 + 1px);
}

.sheet-tabs-modal > div {
	width: 200px;
	height: var(--page_padding);
	border-bottom: 1px solid white;
}

.sheet-tab {
	font-size: 0.8rem;
	font-weight: bold;
	border: none;
	cursor: pointer;
	letter-spacing: 1px;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	padding: 0px 10px;
	border-right: 1px solid white;
	white-space: nowrap;
	color: var(--webl_lightgray);
	background-color: var(--webl_black);
	min-width: 100px;
	width: fit-content;
	flex: 0 0 auto;
}

.sheet-tab-selected {
	color: var(--webl_black);
	background-color: var(--webl_lightgreen);
}

.sheet-arrow-button {
	width: var(--page_padding);
	padding: 0 10px;
	background-color: var(--webl_green);
	border: none;
	cursor: pointer;
	outline: none;
	color: white;
	font-size: 0.8rem;
	user-select: none;
}

#spreadsheet-actions img,
#sheet-hamburger-menu-container img {
	width: 45px;
	height: 25px;
}

#spreadsheet-actions > button,
#sheet-hamburger-menu-container > button {
	display: flex;
	border-left: 1.5px solid white;
	border-top: none;
	border-bottom: none;
	border-right: none;
	background: none;
	padding: 0;
	cursor: pointer;
	justify-content: center;
	align-items: center;
}

#sheet-hamburger-menu-container > button {
	border-left: none;
	border-right: 1.5px solid white;
}

#spreadsheet-actions {
	display: flex;
	height: var(--page_padding);
	margin-left: auto;
	justify-content: flex-start;
}

#sheet-hamburger-menu-container {
	display: flex;
	height: var(--page_padding);
}

@media screen and (min-width: 600px) {
	#sheet-hamburger-menu-container,
	.sheet-tabs-modal {
		display: none;
	}
}

@media screen and (max-width: 600px) {
	.sheet-tabs-container,
	.sheet-arrow-button {
		display: none;
	}
}

@media screen and (max-width: 350px) {
	#spreadsheet-actions img,
	#sheet-hamburger-menu-container img {
		width: 35px;
		height: 25px;
	}
}
