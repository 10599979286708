.file-upload-container {
	height: auto;
	margin: auto;
	width: 550px;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.file-upload-buttons-container,
.choose-file-onedrive-container {
	height: auto;
	width: auto;
	text-align: center;
	display: flex;
	align-items: center;
}

.choose-file-onedrive-container {
	padding-top: 5px;
}

.file-upload-buttons-container {
	flex-direction: column;
	height: auto;
}

#choose-file-onedrive-field {
	width: 400px !important;
}

.file-upload-component {
	border: 1px solid var(--alma_blue);
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 5px;
	margin-bottom: 10px;
}

.upload-file-input {
	z-index: 1;
	width: 146px;
	position: absolute;
	height: 42px;
	visibility: hidden;
}

.choose-file-container,
.onedrive-file-picker {
	margin: auto;
}

.onedrive-file-picker {
	margin-bottom: 50px !important;
}
.choose-file-container > * {
	background-color: var(--alma_blue) !important;
	color: white;
	height: var(--page_padding);
}

.file-upload-button-container {
	display: flex;
	justify-content: center;
	margin: 15px 0;
}

.file-upload-button-container > * {
	background-color: var(--alma_blue) !important;
	color: white;
}

.file-upload-button-container > * :hover {
	background-color: #e8666d !important;
}

.automatic-identification-container {
	text-align: center;
	font-style: italic;
	cursor: pointer;
	margin: 15px 0px 15px 0px;
}

.automatic-identification-container :hover {
	text-decoration: underline;
}

.webl-control-panel-container {
	width: 100%;
	max-width: 700px;
	display: flex;
	flex-direction: column;
	margin: auto;
	padding: 50px;
	border-top: 1px solid var(--alma_blue);
}

#webl-control-panel-table-container .rc-scrollbars-view {
	position: relative !important;
}

#webl-control-panel-table-container .rc-scrollbars-track-v {
	display: none;
}

.webl-control-panel-top-container {
	display: flex;
	width: 100%;
	margin-bottom: 20px;
}

.webl-control-panel-list {
	width: 100%;
	height: auto;
	display: flex;
	flex-direction: column;
	margin: auto;
	margin-bottom: 30px;
	align-items: center;
}

.webl-control-panel-item {
	border-radius: 4px;
	border: 1px solid var(--alma_blue);
	box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
		rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: space-between;
	margin: 10px auto;
	padding: 5px;
	width: 100%;
}

.webl-control-panel-item > div {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 200px;
}

.icon-button {
	color: var(--alma_blue) !important;
}

.no-webls-message {
	margin-top: 20px;
}

.dialog_button {
	color: var(--alma_blue) !important;
	font-weight: bold !important;
}

.instructions_container {
	text-align: center;
	padding: 20px;
}

.instructions_images_container_top > div {
	float: left;
	margin: 20px;
}

.instructions_images_container_top > div > img {
	box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
		rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}

.instructions_images_container_bottom > div {
	margin: 20px;
}

.instructions_images_container_bottom > div > img {
	box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
		rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}

.instructions_text {
	margin: 20px 5px;
	color: var(--alma_blue);
}

.download_sample_container {
	cursor: pointer;
}

.textfield {
	margin-top: 10px !important;
}

.automatic-identification-steps > ol > li {
	margin: 10px 0px;
}

.buttons-container {
	display: flex;
	width: inherit;
	flex-direction: column;
}

.open-file-picker {
	display: flex;
	flex-direction: column;
	padding-top: 10px;
}

.open-file-picker > .MuiFormGroup-root {
	margin-left: 11px !important;
	margin-top: -7px !important;
}

.open-file-picker > .MuiFormGroup-root > * > * {
	font-size: 12px !important;
}

@media screen and (max-width: 450px) {
	#webl-uploader-helptext-container {
		display: flex;
		flex-direction: column;
	}
}
