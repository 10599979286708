.rounded-borders {
	border: 2px solid var(--webl_black) !important;
	border-radius: 8px;
}

#webl-uploader-main-container {
	display: flex;
	background-color: white;
	width: 100%;
	padding: 20px;
	flex-direction: column;
	margin: 20px 0px;
}

#webl-uploader-buttons-container {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

#webl-uploader-buttons-container button,
#webl-uploader-buttons-container button > * {
	letter-spacing: -1px;
}

#webl-uploader-main-button-container {
	min-width: 70%;
	width: auto;
}

#webl-uploader-other-buttons-container {
	width: 28%;
	max-width: 250px;
}

#upload-spreadsheet-container,
#onedrive-picker-container,
#onedrive-link-container {
	display: flex;
	width: 100%;
	font-weight: bold;
}

#onedrive-picker-container,
#onedrive-link-container {
	display: flex;
	height: 100%;
	align-items: center;
	justify-content: space-between;
}

#webl-uploader-main-container > div > div > div > button {
	background: none;
	color: var(--webl_black);
	padding: 0;
	cursor: pointer;
	font-size: 16px;
	font-weight: bold;
	height: 100%;
}

#upload-spreadsheet-container button,
#upload-spreadsheet-container label {
	display: flex;
	width: 100%;
	height: 100%;
}

#upload-spreadsheet-button {
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	color: inherit;
	cursor: pointer;
	padding: 15px 0px;
	gap: 15px;
}

#upload-spreadsheet-button > img {
	width: 36px;
	height: 36px;
}

#onedrive-picker-container button,
#onedrive-link-container button {
	width: 100%;
}
#webl-uploader-other-buttons-container {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	font-weight: bold;
}

.webl-uploader-other-buttons-text {
	display: flex;
	font-size: 14px;
	margin-left: auto;
	width: auto;
	min-width: 190px;
}

.webl-uploader-other-buttons-text > * {
	margin-left: auto;
	white-space: nowrap;
}

#onedrive-picker-container .webl-uploader-other-buttons-text {
	cursor: pointer;
}

.webl-uploader-other-buttons-text .sc-bdnyFh,
#home-top-switch-container .sc-bdnyFh {
	margin-left: 10px;
}

#webl-upload-method-container {
	display: flex;
	margin: auto;
	margin-top: var(--page_padding);
	margin-bottom: -30px;
	padding: 0px 20px;
	height: 20px;
	background-color: white;
	font-weight: bold;
	cursor: pointer;
}

/* for the switch */
#webl-upload-method-container > label {
	margin: 0px 10px;
}

#webl-uploader-helptext-container :nth-child(1) {
	font-size: 24px;
	font-weight: bold;
	margin-right: 10px;
}

#webl-uploader-helptext-container :nth-child(2) {
	font-size: 13px;
	font-style: italic;
}

#webl-uploader-helptext-container :nth-child(2):hover {
	cursor: pointer;
	text-decoration: underline;
}

#upload-spreadsheet-button:hover {
	background-color: var(--webl_green) !important;
	border-color: var(--webl_green) !important;
	color: white;
}

#onedrive-picker-container button:hover,
#onedrive-link-container button:hover {
	background-color: var(--webl_lightgray) !important;
}

#upload-spreadsheet-container input[type="file"] {
	display: none;
}

#webl-uploader-multiple-sheets-dialog {
	display: flex;
	flex-direction: column;
	align-items: baseline;
}

#webl-uploader-multiple-sheets-dialog > :first-child {
	color: #eb1920d3;
	font-weight: bold;
	font-size: 18px;
}

#webl-uploader-multiple-sheets-dialog > :nth-child(2) {
	margin-top: 10px;
}

#webl-uploader-multiple-sheets-dialog > :nth-child(3) {
	margin: 30px 0px 15px;
}

.css-1gazu1v-MuiButtonBase-root-MuiMenuItem-root.Mui-selected {
	background-color: inherit !important;
}

@media screen and (max-width: 800px) {
	.webl-uploader-other-buttons-text {
		font-size: 11px;
		min-width: auto;
	}
}

@media screen and (max-width: 660px) {
	#webl-uploader-buttons-container {
		flex-direction: column;
	}

	#webl-uploader-main-button-container {
		width: 100%;
		margin-bottom: 10px;
	}

	#webl-uploader-other-buttons-container {
		width: 100% !important;
		max-width: none;
	}

	#webl-uploader-other-buttons-container > div {
		height: 100% !important;
	}

	#onedrive-link-container > button {
		padding: 10px !important;
	}
}

@media screen and (max-width: 500px) {
	#webl-upload-method-container {
		padding: 0px 10px;
	}

	#webl-upload-method-container > span {
		font-size: 11px;
		line-height: 20px;
	}
}

@media screen and (max-width: 430px) {
	#webl-uploader-buttons-container button,
	#webl-uploader-buttons-container label {
		font-size: 14px !important;
		padding: 10px;
	}
	#webl-upload-method-container > span {
		font-size: 10px;
		line-height: 20px;
	}

	.webl-uploader-other-buttons-text {
		display: flex;
		flex-direction: column;
		align-items: flex-end;
		min-width: 100px !important;
	}

	.webl-uploader-other-buttons-text > * {
		white-space: wrap;
	}

	#onedrive-picker-container > .webl-uploader-other-buttons-text {
		gap: 5px;
	}

	.webl-uploader-other-buttons-text span {
		text-align: right !important;
	}
}

@media screen and (max-width: 400px) {
	.webl-upload-method-hide-text {
		display: none !important;
	}

	#webl-upload-method-container {
		text-transform: capitalize;
	}
}
