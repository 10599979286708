#onedrive-link-field-container > .confirm_btn-buttons {
	margin-top: 0px !important;
}

#onedrive-link-field-container {
	gap: 10px;
}

@keyframes fadeInAnimation {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}
