/* BURGUNDY THEME */

/* Font*/
@font-face {
	font-family: "LibreBaskerville";
	src: url("../../fonts/LibreBaskerville/LibreBaskerville-Regular.ttf")
		format("truetype");
	font-weight: normal;
}
@font-face {
	font-family: "LibreBaskerville";
	src: url("../../fonts/LibreBaskerville/LibreBaskerville-Bold.ttf")
		format("truetype");
	font-weight: bold;
}
@font-face {
	font-family: "LibreBaskerville";
	src: url("../../fonts/LibreBaskerville/LibreBaskerville-Italic.ttf")
		format("truetype");
	font-style: italic;
}

.webl-html[webl_theme="Burgundy"] {
	padding: 30px !important;
	background-color: #f2f2f2 !important;
	display: inline-block;
}

/* Text Cells */
.webl-html table td[webl_theme="Burgundy"],
.webl-html table td[webl_theme="Burgundy"] select,
.webl-html table td[webl_theme="Burgundy"] a span,
.webl-html table td[webl_theme="Burgundy"] select option {
	background: #ffffff !important;
	outline: solid 1px var(--webl_lightgray) !important;
	font-family: "LibreBaskerville", Times, serif !important;
	color: #1a1a1a !important;
	padding: 3px 6px !important;
}

/* Input Cells */
.webl-html table td[contenteditable][webl_theme="Burgundy"] {
	background-color: #ffffff !important;
	border: 2px solid #1a1a1a !important;
	box-shadow: 0 !important;
	color: #1a1a1a !important;
	font-style: normal !important;
	outline: none !important;
}

/* Sum Cells */
.webl-html table td[x\:fmla][webl_theme="Burgundy"] {
	background-color: #771017 !important;
	outline: solid 1px #e6a99d !important;
	color: #f2f2f2 !important;
}

/* Empty Cells */
.webl-html table td[webl_theme="Burgundy"]:empty {
	background-color: #f2f2f2 !important;
	outline: none !important;
}

/* Aditional Properties */
.webl-html table[table_spacing="true"][webl_theme="Burgundy"] {
	border-collapse: unset;
	border-spacing: 5px !important;
	padding: 0px 5px;
}

.webl-html td[gridlines="true"][webl_theme="Burgundy"],
.webl-html td[gridlines="true"][webl_theme="Burgundy"]:empty {
	border: 1px solid #2f2f2f;
	border-collapse: collapse;
	padding: 0px 5px;
}

.webl-html td[contenteditable][input_borders="true"][webl_theme="Burgundy"] {
	outline: -webkit-focus-ring-color auto 1px !important;
	padding: 0px 5px;
}
.webl-html td[round_borders="true"][webl_theme="Burgundy"] {
	border-radius: 5px !important;
	padding: 0px 5px;
}
