.basic-button {
	background-color: var(--webl_black);
	color: white;
	width: 110px;
	padding: 0px calc(var(--page_padding) / 4);
}

.basic-button-arrow {
	color: white;
	float: right;
	vertical-align: middle;
}

#display_icon {
	color: #8cc63f;
}

.inner {
	list-style-type: none;
	padding-left: 0px;
	overflow: hidden;
	white-space: nowrap;
	flex-grow: 1;
}

.inner-opened {
	transition: max-width 0.8s ease-in-out;
}

.inner-closed {
	max-width: 0;
	transition: max-width 0.4s ease-in-out;
}

.inner li {
	position: relative;
	display: inline-block;
}

.nav-bar {
	display: flex;
	overflow: hidden;
}

.nav-bar button {
	text-align: left;
	height: var(--page_padding);
	font-size: 13px;
	border: none;
	cursor: pointer;
	padding-top: 7px;
	padding-bottom: 7px;
	vertical-align: middle;
	margin-right: 1px;
}

.visible-button {
	background-color: #8cc63f;
	color: white;
}

.hidden-button {
	background-color: #395643;
	color: white;
}

.select-button {
	background-color: #395643;
	color: white;
}

.webl-title-icon {
	width: 20px !important;
}

.menu-icon {
	width: 30px !important;
	vertical-align: middle;
	margin-right: 8px;
	margin-top: -5px;
	margin-bottom: -2.5px;
}

.menu-button {
	display: inline-block;
	padding-left: 10px;
	padding-right: 15px;
}
